/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, Link } from "gatsby"
import { RichText } from "prismic-reactjs"
import { Box, Flex } from "rebass"
import {
  MdChevronRight,
  MdReportProblem,
  MdLocalDrink,
  MdSwapCalls,
  MdSettingsInputSvideo,
  MdPerson,
} from "react-icons/md"

import {
  Container,
  Layout,
  Section,
} from "@giraldomac/gatsby-theme-mmdbase/src/components/layout"
import { SEO } from "@giraldomac/gatsby-theme-mmdbase/src/components/elements"
import { PubHero } from "../../components/elements"

const Index = ({ data }) => {
  const page = data.prismic.faqs

  return (
    <Layout>
      <SEO
        meta_title={page.meta_title}
        meta_description={page.meta_description}
      />
      <PubHero title={RichText.asText(page.title)} />
      <Section>
        <Container>
          <Flex flexWrap="wrap" justifyContent="center">
            <Box
              width={["full", 4 / 5, 3 / 4]}
              sx={{
                backgroundColor: "wrapperbox",
                padding: [4, 5],
              }}
            >
              {RichText.render(page.content)}
            </Box>

            {/* Emergency */}
            <Box width={["full", 4 / 5, 3 / 4]} color="white">
              <Box
                sx={{
                  bg: "red.5",
                  p: 4,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2 sx={{ margin: 0 }}>Emergency Related</h2>
                <MdReportProblem sx={{ fill: "red.6", fontSize: "3em" }} />
              </Box>
              <Box
                sx={{
                  bg: "red.6",
                  p: 4,
                }}
              >
                <p sx={{ marginBottom: 0 }}>
                  When emergencies occur, it is easy to become overwhelmed.
                  Learn how you can prepare yourself and your facilities for the
                  unexpected. Don't panic. We've got all the information right
                  here.
                </p>
                <Link
                  to="/faqs/emergency"
                  sx={{
                    color: "white",
                    textDecoration: "none",
                    display: "block",
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                    "&:hover": { color: "white", textDecoration: "underline" },
                  }}
                >
                  Learn More <MdChevronRight />
                </Link>
              </Box>
            </Box>

            {/* Water */}
            <Box width={["full", 4 / 5, 3 / 4]} color="white">
              <Box
                sx={{
                  bg: "blue.5",
                  p: 4,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2 sx={{ margin: 0 }}>Water Related</h2>
                <MdLocalDrink sx={{ fill: "blue.6", fontSize: "3em" }} />
              </Box>
              <Box
                sx={{
                  bg: "blue.6",
                  p: 4,
                }}
              >
                <p sx={{ marginBottom: 0 }}>
                  Do you have questions about the quality of your water, or your
                  water services from PUB? Find all the answers to your
                  questions here. We provide tips, problem prevention
                  strategies, and general information about your water.
                </p>
                <Link
                  to="/faqs/water"
                  sx={{
                    color: "white",
                    textDecoration: "none",
                    display: "block",
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                    "&:hover": { color: "white", textDecoration: "underline" },
                  }}
                >
                  Learn More <MdChevronRight />
                </Link>
              </Box>
            </Box>

            {/* Sewer */}
            <Box width={["full", 4 / 5, 3 / 4]} color="white">
              <Box
                sx={{
                  bg: "yellow.5",
                  p: 4,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2 sx={{ margin: 0 }}>Sewer Related</h2>
                <MdSwapCalls sx={{ fill: "yellow.6", fontSize: "3em" }} />
              </Box>
              <Box
                sx={{
                  bg: "yellow.6",
                  p: 4,
                }}
              >
                <p sx={{ marginBottom: 0 }}>
                  Sewer problems, such as leaks, can be messy and inconvenient.
                  Most importantly, they can lead to more severe and damaging
                  plumbing issues at your location. Learn the signs of plumbing
                  leaks and what you can do about them.
                </p>
                <Link
                  to="/faqs/sewer"
                  sx={{
                    color: "white",
                    textDecoration: "none",
                    display: "block",
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                    "&:hover": { color: "white", textDecoration: "underline" },
                  }}
                >
                  Learn More <MdChevronRight />
                </Link>
              </Box>
            </Box>

            {/* Meter */}
            <Box width={["full", 4 / 5, 3 / 4]} color="white">
              <Box
                sx={{
                  bg: "orange.5",
                  p: 4,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2 sx={{ margin: 0 }}>Meter Related</h2>
                <MdSettingsInputSvideo
                  sx={{ fill: "orange.6", fontSize: "3em" }}
                />
              </Box>
              <Box
                sx={{
                  bg: "orange.6",
                  p: 4,
                }}
              >
                <p sx={{ marginBottom: 0 }}>
                  Know exactly what to expect when it comes to your meter and
                  its reading by studying up on our most frequently asked
                  meter-related questions. Here, we will cover what you need to
                  know about the meter structure and your bill.
                </p>
                <Link
                  to="/faqs/meter"
                  sx={{
                    color: "white",
                    textDecoration: "none",
                    display: "block",
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                    "&:hover": { color: "white", textDecoration: "underline" },
                  }}
                >
                  Learn More <MdChevronRight />
                </Link>
              </Box>
            </Box>

            {/* Customer Service */}
            <Box width={["full", 4 / 5, 3 / 4]} color="white">
              <Box
                sx={{
                  bg: "green.5",
                  p: 4,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2 sx={{ margin: 0 }}>Customer Service</h2>
                <MdPerson sx={{ fill: "green.6", fontSize: "3em" }} />
              </Box>
              <Box
                sx={{
                  bg: "green.6",
                  p: 4,
                }}
              >
                <p sx={{ marginBottom: 0 }}>
                  PUB strives to go above and beyond for our customers. For
                  general inquiries about our company, our services, or your
                  account, find the information you're looking for in our
                  customer service frequently asked questions.
                </p>
                <Link
                  to="/faqs/customer-service"
                  sx={{
                    color: "white",
                    textDecoration: "none",
                    display: "block",
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                    "&:hover": { color: "white", textDecoration: "underline" },
                  }}
                >
                  Learn More <MdChevronRight />
                </Link>
              </Box>
            </Box>
          </Flex>
        </Container>
      </Section>
    </Layout>
  )
}

export default Index

export const query = graphql`
  query FAQsQuery {
    prismic {
      faqs(lang: "en-us", uid: "faqs") {
        title
        meta_title
        meta_description
        content
        _linkType
      }
    }
  }
`
